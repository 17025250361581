<template>
  <div>
    <div>
      <div class="flex align-items marginBottom10 flex-wrap">
        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">会员编号:</span>
          <div class="width200">
            <el-input
              v-model="searchData.code"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">会员手机号:</span>
          <div class="width200">
            <el-input
              v-model="searchData.phone"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="11"
            ></el-input>
          </div>
        </div>

        <div class="flex align-items marginRight10 marginBottom10">
          <span class="marginRight10">会员昵称:</span>
          <div class="width200">
            <el-input
              v-model="searchData.nickname"
              placeholder="请输入"
              @keyup.enter.native="handleSearch"
              clearable
              maxlength="20"
            ></el-input>
          </div>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            class="marginLeft10"
          >查询</el-button>
        </div>

        <div class="marginRight10 marginBottom10">
          <el-button
            type="info"
            icon="el-icon-refresh-right"
            @click="handleReset"
            class="marginLeft10"
          >重置</el-button>
        </div>
      </div>
    </div>

    <avue-crud
      ref="ongoingTask"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          v-if="!row.check"
          size="mini"
          @click="rowClick(row)"
        >选择</el-button>
        <el-button
          type="text"
          v-else
          size="mini"
          disabled
        >已选择</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { userList } from "@/api/region/commission";
export default {
  name: "goods",
  props: {
    defaultData: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectRow: "",

      searchData: {
        code: "",
        nickname: "",
        phone: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "mini",
        selection: false, // 显示多选框
        reserveSelection: false,
        rowKey: "id",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "会员编号",
            prop: "code",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "会员openid",
            prop: "openid",
          },
          {
            label: "注册时间",
            prop: "registerTime",
          },
          {
            label: "会员等级",
            prop: "userLevel",
          },
        ],
      },
    };
  },
  watch: {
    show(value) {
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },
  },
  methods: {
    rowClick(row) {
      this.selectRow = row;
      this.selectClassify();
    },

    onLoad() {
      this.showLoading = true;
      userList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;

          if (this.show) {
            // this.$nextTick(() => {
            this.selectRow = this.defaultData;
            this.tableData.map((item) => {
              if (item.id === this.selectRow.id) {
                item.check = true;
              } else {
                item.check = false;
              }
            });
            // });
          } else {
            this.selectRow = {};
          }
        }
      });
    },
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleReset() {
      this.searchData = {};
      this.page.currentPage = 1;
      this.onLoad();
    },
    selectClassify() {
      this.$emit("change", this.selectRow);
    },
  },
};
</script>

<style scoped>
/* .crud /deep/.el-card__body .el-form .el-table .el-table__fixed-right {
  height: 100% !important;
} */
</style>
